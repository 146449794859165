(function ($) {

    var $window = $(window);

    var fn = {

        /*
            Launch
        */

        Launch: function () {
            fn.Customizer();
            fn.Theme();
            fn.App();
        },

        /*
            Customizer
        */

        Customizer: function () {
            var $html       = $('html'),
                cookieParts = document.cookie,
                $customizer = $('.customizer'),
                date        = new Date();

            if(cookieParts.includes('customizer=open')) {
                $customizer.addClass('customizer--active');
            } else {
                $customizer.removeClass('customizer--active');
            }

            $('[data-toggle-customizer]').on('click', function(e) {
                e.preventDefault();

                if($customizer.hasClass('customizer--active')) {
                    $customizer.removeClass('customizer--active');

                    document.cookie = 'customizer=open; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
                } else {
                    $customizer.addClass('customizer--active');

                    document.cookie = 'customizer=open; expires=' + date.setTime(date.getTime() + (60 * 60 * 24 * 365)) + '; path=/;';
                }
            });

            $('input[type=radio][name="customizer-color"]').on('change', function() {
                var value           = this.value,
                    $styleBootstrap = $('#style-bootstrap'),
                    $styleApp       = $('#style-app');

                $html.removeClass('ink-font-size-sm');
                $html.removeClass('ink-font-size-lg');

                if(value == 'dl') {
                    $styleBootstrap.attr('href', './assets/css/bootstrap.min.css');
                    $styleBootstrap.attr('data-theme-light', './assets/css/bootstrap.min.css');
                    $styleBootstrap.attr('data-theme-dark', './assets/css/bootstrap-dark.min.css');

                    $styleApp.attr('href', './assets/css/app.min.css');
                    $styleApp.attr('data-theme-light', './assets/css/app.min.css');
                    $styleApp.attr('data-theme-dark', './assets/css/app-dark.min.css');
                } else if (value == 'bw') {
                    $styleBootstrap.attr('href', './assets/css/bootstrap-white.min.css');
                    $styleBootstrap.attr('data-theme-light', './assets/css/bootstrap-white.min.css');
                    $styleBootstrap.attr('data-theme-dark', './assets/css/bootstrap-black.min.css');

                    $styleApp.attr('href', './assets/css/app-white.min.css');
                    $styleApp.attr('data-theme-light', './assets/css/app-white.min.css');
                    $styleApp.attr('data-theme-dark', './assets/css/app-black.min.css');
                }
            });

            $('input[type=radio][name="customizer-font-size"]').on('change', function() {
                var value = this.value;

                $html.removeClass('ink-font-size-sm');
                $html.removeClass('ink-font-size-lg');

                if(value == 'sm') {
                    $html.addClass('ink-font-size-sm');
                } else if (value == 'lg') {
                    $html.addClass('ink-font-size-lg');
                }
            });

            $('input[type=radio][name="customizer-border-radius"]').on('change', function() {
                var value = this.value;

                $html.removeClass('ink-border-radius-none');
                $html.removeClass('ink-border-radius-lg');

                if(value == 'none') {
                    $html.addClass('ink-border-radius-none');
                } else if (value == 'lg') {
                    $html.addClass('ink-border-radius-lg');
                }
            });

            $('input[type=radio][name="customizer-box-shadow"]').on('change', function() {
                var value = this.value;

                $html.removeClass('ink-box-shadow-none');
                $html.removeClass('ink-box-shadow-lg');

                if(value == 'none') {
                    $html.addClass('ink-box-shadow-none');
                } else if (value == 'lg') {
                    $html.addClass('ink-box-shadow-lg');
                }
            });

            $('input[type=radio][name="customizer-picture-ratio"]').on('change', function() {
                var value = this.value;

                $html.removeClass('ink-picture-ratio-square');

                if(value == 'square') {
                    $html.addClass('ink-picture-ratio-square');
                }
            });

        },

        /*
            Theme
        */

        Theme: function () {
            function changeDarkMode(isChecked) {
                if(isChecked) {
                    $('[data-theme-light]').each(function() {
                        var $this = $(this);

                        $this.attr('href', $this.attr('data-theme-dark'));
                        $html.addClass('theme-dark');
                        $html.removeClass('theme-light');
                    });
                } else {
                    $('[data-theme-dark]').each(function() {
                        var $this = $(this);
                    
                        $this.attr('href', $this.attr('data-theme-light'));
                        $html.addClass('theme-light');
                        $html.removeClass('theme-dark');
                    });
                }
            }

            var $checkbox = $('[data-toggle-theme] input'),
                $html     = $('html');

            changeDarkMode($checkbox.is(':checked'));

            $('[data-toggle-theme] input').on('change', function(e) {
                e.preventDefault();

                changeDarkMode(this.checked);
            });
        },

        /*
            App
        */

        App: function () {

            var lastScrollPos = 0;
            
            // On Scroll

            $window.on('scroll', function () {

                var $this    = $(this),
                    scrollPx = $this.scrollTop(),
                    $header  = $('.header');

                // Header

                if (scrollPx > 0) {
                    $header.addClass('header--sticky');
                } else {
                    $header.removeClass('header--sticky');
                }

                if(scrollPx > lastScrollPos) {
                    $header.removeClass('header--active');
                } else {
                    $header.addClass('header--active');
                }

                lastScrollPos = (scrollPx < 0) ? 0 : scrollPx;

            }).trigger('scroll');

        }

    };

    fn.Launch();

})(jQuery);